import { useConnectUser } from '../Chat/hooks'
import { navigate } from 'gatsby-link'
import React, { useEffect, useMemo, useState } from 'react'

function getQueryParam(url, param) {
  const urlParams = new URLSearchParams(url)
  return urlParams.get(param)
}

function decodeJwt(token) {
  if (!token) return null

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  console.log('ffff', base64)

  return JSON.parse(window.atob(base64))
}

function useQuery(queryFunction, options = {}) {
  const [data, setData] = useState(options.default)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setData(await queryFunction())
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [])

  return { data, isLoading, error }
}

export const useProfileData = (options = {}) => {
  const { data: profileData, isLoading } = useQuery(
    async () => {
      if (typeof window === 'undefined') {
        return
      }

      const l = window.localStorage.getItem('doggos_l')

      if (!l) {
        if (!options.optional) {
          navigate('/', {
            replace: true,
            state: {
              returnTo: window.location.href,
            },
          })
        } else {
          console.debug('Anonymous user')
        }

        return null
      }

      const response = await fetch('/api/login', {
        method: 'post',
        body: JSON.stringify({ l }),
      })

      if (!response.ok && !options.optional) {
        window.localStorage.removeItem('doggos_l')
        navigate('/', {
          replace: true,
          state: {
            returnTo: window.location.href,
          },
        })
      }

      const login = await response.json()
      window.localStorage.setItem('doggos_l', login.l)

      return decodeJwt(window.localStorage.getItem('doggos_l'))
    },
    {
      default:
        typeof window === 'undefined'
          ? null
          : decodeJwt(window.localStorage.getItem('doggos_l')),
    }
  )

  return profileData
}

function ConnectUser(props) {
  const profile = useProfileData()
  console.debug('connect user2', profile, props.loginJWT)

  useConnectUser(
    'vt26ddprnwsx',
    { ...profile, id: profile.user_id },
    props.loginJWT
  )

  return props.children
}

function AuthProvider(props) {
  const [state, setState] = useState(
    typeof window !== 'undefined'
      ? {
          loginJWT: localStorage.getItem('doggos_l'),
        }
      : {
          loginJWT: null,
        }
  )

  useEffect(() => {
    const loginJWT = getQueryParam(window.location.search, 'doggos_l')

    if (loginJWT) {
      console.debug('found JWT')
      setState({ ...state, loginJWT })
      localStorage.setItem('doggos_l', loginJWT)
      navigate('/dashboard', { replace: true })
    }
  }, [])

  return props.children
}

export default AuthProvider
